@import "../../../scss/variables.scss";

.container :global {
  --PhoneInputCountryFlag-backgroundColor--loading: #ffffff;
  display: flex;
  height: 100vh;
  flex: 1;
  max-width: 500px;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 30px;

  .phone-input {
    input, select {
      background-color: #ffffff;
      border: none !important;
      color: #000000;
      box-shadow: none !important;
      outline: none !important;

      &:focus {
        border: none !important;
      }
    }
  }

  .card {
    width: 350px;
  }

  .link {
    display: block;
    font-size: .8rem;
    text-decoration: unset;
  }

  .preschoolsListTitle {
    font-size: 14px;
    font-weight: 500;
    color: $mainColor;
  }

  .preschool-title {
    display: flex;
    align-items: center;
    gap: 4px;
    color: $mainColor;
    font-weight: 600;
  }

  .login-option-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
    cursor: pointer;
    svg {
      fill: $mainColor;
    }
  }

  .loginOptionText {
    font-size: 13px;
    font-weight: 600;
  }
}
